define("ember-svg-jar/inlined/zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\"><path fill=\"#0B5CFF\" d=\"M20 11.4c0 1 0 2-.3 3.1a8 8 0 01-6.6 6.6 19.8 19.8 0 01-6.2 0 8 8 0 01-6.7-6.6 19.8 19.8 0 010-6.3A8 8 0 017 1.6a19.8 19.8 0 016.2 0 8 8 0 016.6 6.6c.2 1 .3 2 .3 3.2z\"/><path fill=\"url(#b)\" d=\"M20 11.4c0 1 0 2-.3 3.1a8 8 0 01-6.6 6.6 19.8 19.8 0 01-6.2 0 8 8 0 01-6.7-6.6 19.8 19.8 0 010-6.3A8 8 0 017 1.6a19.8 19.8 0 016.2 0 8 8 0 016.6 6.6c.2 1 .3 2 .3 3.2z\"/><path fill=\"url(#c)\" d=\"M20 11.4c0 1 0 2-.3 3.1a8 8 0 01-6.6 6.6 19.8 19.8 0 01-6.2 0 8 8 0 01-6.7-6.6 19.8 19.8 0 010-6.3A8 8 0 017 1.6a19.8 19.8 0 016.2 0 8 8 0 016.6 6.6c.2 1 .3 2 .3 3.2z\"/><mask id=\"d\" width=\"14\" height=\"14\" x=\"3\" y=\"4\" maskUnits=\"userSpaceOnUse\" style=\"mask-type:luminance\"><path fill=\"#fff\" d=\"M16.4 5H3.6v12.8h12.8V4.9z\"/></mask><g mask=\"url(#d)\"><path fill=\"#fff\" d=\"M12.1 13.9c0 .5-.4 1-1 1H6.4c-1.2 0-2.1-1-2.1-2.1v-4c0-.5.5-1 1-1H10c1.2 0 2.1 1 2.1 2.1v4zM15 8.4l-1.6 1.2a1 1 0 00-.4.9v1.8c0 .3.1.6.4.8l1.6 1.2c.3.3.8 0 .8-.4v-5c0-.5-.5-.7-.8-.5z\"/></g></g><defs><radialGradient id=\"b\" cx=\"0\" cy=\"0\" r=\"1\" gradientTransform=\"matrix(13.4826 0 0 11.2685 10 10.2)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".8\" stop-color=\"#0B5CFF\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#003CB3\"/></radialGradient><radialGradient id=\"c\" cx=\"0\" cy=\"0\" r=\"1\" gradientTransform=\"matrix(13.4778 0 0 11.2644 10 12.5)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".8\" stop-color=\"#0B5CFF\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#71A5F1\"/></radialGradient><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 .6h20v20.7H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 20 22"
    }
  };
});